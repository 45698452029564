<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getCarsRegistry.name"
                    :avatarData="getCarsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                    :title="getCarsRegistry.name"
                    :items="getCarsTable.items"
                    :headers="headers"
                    :length="getCarsTable.pages"
                    :page="getCarsTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getCarsSearchFields.searchFields"
                    :loading="getCarsTable.loading"
                >
                    {{ getCarsSearchFields.searchFields }}
                    <Columns slot="columns" :items="getCarsTable.items" />
                </Table>
                <Modal
                    :title="$t('cars:additionCar')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addCar()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('cars:addCars') }}</span>
                    </v-tooltip>
                </div></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Cars/Table/Columns'
import AEContent from './../../../components/Registries/Cars/Modal/Content'
import Buttons from './../../../components/Registries/Cars/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('cars:model'),
                    value: 'model',
                    width: '19%',
                    showSearch: true,
                },
                {
                    text: this.$t('cars:registrationNumber'),
                    value: 'registrationNumber',
                    width: '16%',
                    showSearch: true,
                },
                {
                    text: this.$t('cars:user'),
                    value: 'driver.name join driver.lastname',
                    width: '15%',
                    showSearch: true,
                },
                {
                    text: this.$t('cars:dateOfTheNextInspection'),
                    value: 'dateOfTheNextInspection',
                    width: '14%',
                },
                {
                    text: this.$t('cars:insuranceUpTo'),
                    value: 'insuranceUpTo',
                    width: '14%',
                },
                {
                    text: this.$t('cars:yearOfProduction'),
                    value: 'yearOfProduction',
                    width: '13%',
                    align: 'left',
                },
                {
                    text: this.$t('cars:status'),
                    value: 'status',
                    width: '100px',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getCarsTable',
            'getCarsSearchFields',
            'getCarsRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            // ' ',
            'fetchUsers',
            'fetchCarsTable',
        ]),
        ...mapMutations([
            'clearCarsModal',
            'clearCarErrors',
            'setCarsTable',
            'setCarsSearch',
            'clearCarsTable',
        ]),
        closeModal() {
            // this.clearCarsErrors()
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addCar() {
            await void this.fetchUsers({
                select: 'name lastname mail status',
                where: {
                    isUser: true,
                    'status.state': 'ACTIVE',
                    isDeleted: false,
                },
            })
            this.open = true
            this.clearCarErrors()
            this.clearCarsModal()
        },
        setSearchFields(searchFields) {
            this.setCarsSearch(searchFields)
        },
        setSearch(search) {
            this.setCarsTable({ search, page: 1 })
            this.fetchCarsTable()
        },
        setPage(page) {
            this.setCarsTable({ page })
            this.fetchCarsTable()
        },
        setSort(sort) {
            this.setCarsTable({ sort })
            this.fetchCarsTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearCarsTable()
            this.fetchCarsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/cars/:id')
            store.commit('clearCarsTable')
        store.dispatch('fetchCarsTable').then(() => next())
    },
}
</script>
